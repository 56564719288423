<template>
  <div class="page-wrapper">
    <div class="left">
      <div class="loginWrap">
        <img alt="Showadz logo" class="logo" src="@/assets/img/showadz-logo.svg" />

        <h1 class="big-title">Create a new Account</h1>

        <form @submit="createNewAccount">
          <input v-model="accountInfos.businessName" type="text" placeholder="Business Name" required />
          <input v-model="accountInfos.profileImg" type="text" placeholder="Profile img URL" required />
          <input v-model="accountInfos.owner" type="text" placeholder="Owner User ID" required />

          <button type="submit" class="bt" :class="{ 'is-loading': isLoading }">
            <div class="bt-text">
              <p>Create</p>
              <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
            </div>
            <p class="loader">
              &nbsp;
              <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#EFF1F2">
                <g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
                  <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                  <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
                  </path>
                </g>
              </svg>
              &nbsp;
            </p>
          </button>
        </form>

        <!-- <p class="or">OR</p> -->

        <!-- Loader -->
        <!-- <div v-show="user === undefined" data-test="loader">
          Authenticating...
        </div> -->

        <!-- Offline instruction -->
        <div v-show="!networkOnLine" data-test="offline-instruction">Please check your connection, login feature is not available offline.</div>

        <!-- <p v-if="loginError">{{ loginError }}</p> -->

        <!-- <section
          v-show="networkOnLine"
          id="firebaseui-auth-container"
        ></section> -->
      </div>
    </div>

    <div class="right">
      <div class="data">
        <h1>Showadz, a platform built for digital marketers and their clients</h1>

        <!-- <div class="bt">
          <p>Demandez vos accès</p>
          <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
        </div> -->
      </div>
      <div class="overlay"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isNil } from 'lodash'
import AccountsDB from '@/firebase/accounts-db'

import { doAlert } from '@/misc/helpers'

export default {
  head: function () {
    return {
      title: {
        inner: 'Account Creation',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} account creation page`,
          id: 'desc',
        },
      ],
    }
  },
  data: () => ({
    isLoading: true,
    loginError: null,
    accountInfos: {
      isActive: true,
      isCompleted: false,
    },
  }),

  computed: {
    ...mapState('app', ['networkOnLine', 'appTitle']),
    ...mapState('authentication', ['user']),
    ...mapState('users', ['account', 'clients']),
  },

  watch: {
    user: {
      async handler(user) {
        if (!isNil(user)) {
          if (user.appRole !== 'admin') {
            this.$router.push('/')
          }

          this.isLoading = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    async createNewAccount(e) {
      e.preventDefault()
      this.isLoading = true
      const AccountsDb = new AccountsDB()
      await AccountsDb.create(this.accountInfos)
      doAlert({
        title: `New account created!`,
        type: 'success',
      })
      this.accountInfos = {
        isActive: true,
        isCompleted: false,
      }
      this.isLoading = false
    },
    ...mapActions('users', ['getUserAccount', 'getUserClients', 'selectClient']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/button.scss';
@import '@/theme/forms.scss';
@import '@/theme/typography.scss';

.page-wrapper {
  display: flex;
}

.left {
  position: relative;
  width: 35vw;
  min-width: 450px;
  height: 100vh;
  padding: 0 40px;
  display: flex;
  align-items: center;

  .logo {
    width: 250px;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .loginWrap {
    width: 100%;
    padding: 0 1vw;
    text-align: center;
  }

  h1 {
    margin-bottom: 30px;
  }

  input[type='text'] {
    margin-bottom: 10px;
  }

  input[type='password'] {
    margin-bottom: 10px;
  }

  .bt {
    width: 100%;

    &:hover {
      padding: 15px 25px 15px 25px;
    }
  }

  .or {
    margin: 15px 0;
    font-weight: 300;
    font-size: 14px;
  }
}

.right {
  position: relative;
  width: 65vw;
  height: 100vh;
  background-image: url('../../assets/img/home-background.jpg');
  background-size: cover;

  .overlay {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #1f2532;
    opacity: 0.8;
    z-index: 1;
  }

  .data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    text-align: center;

    width: 100%;
    max-width: 700px;

    h1 {
      color: #fff;
      font-weight: 300;
      text-align: center;
      font-size: 40px;
      margin-bottom: 25px;
    }

    .bt {
      display: inline-block;
      max-width: 250px;
      text-align: center;
    }
  }
}
</style>
